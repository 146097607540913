import type { Config } from 'tailwindcss'

export const baseSize = {
  main: '1024px',
}
export const baseColor = {
  primary: '#2f59e8',
}

const config: Config = {
  darkMode: 'class',
  content: [
    './src/pages/**/*.{js,ts,jsx,tsx,mdx}',
    './src/components/**/*.{js,ts,jsx,tsx,mdx}',
    './src/app/**/*.{js,ts,jsx,tsx,mdx}',
  ],
  theme: {
    extend: {
      height: {
        ...baseSize,
      },
      minHeight: {
        ...baseSize,
      },
      maxHeight: {
        ...baseSize,
      },
      width: {
        ...baseSize,
      },
      minWidth: {
        ...baseSize,
      },
      maxWidth: {
        ...baseSize,
      },
      backgroundColor: {
        ...baseColor,
      },
      textColor: {
        ...baseColor,
      },
      borderColor: {
        ...baseColor,
      },
    },
  },
  corePlugins: {
    preflight: false,
  },
}
export default config
