'use client'

import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import dayjsIsBetween from 'dayjs/plugin/isBetween'
import dayjsIsSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import dayjsLocaleData from 'dayjs/plugin/localeData'
import dayjsLocalizedFormat from 'dayjs/plugin/localizedFormat'
import dayjsMinMax from 'dayjs/plugin/minMax'
import dayjsUtc from 'dayjs/plugin/utc'
import dayjsZh from 'dayjs/locale/zh'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import '@/styles/globals.css'
import { themeOptions } from '@/theme/mui'

import type { AppProps } from 'next/app'

dayjs.extend(dayjsIsBetween)
dayjs.extend(dayjsIsSameOrAfter)
dayjs.extend(dayjsIsSameOrBefore)
dayjs.extend(dayjsLocaleData)
dayjs.extend(dayjsLocalizedFormat)
dayjs.extend(dayjsMinMax)
dayjs.extend(dayjsUtc)
dayjs.locale({
  ...dayjsZh,
  // 从周日开始一周
  weekStart: 0,
  // 自定义周名称
  weekdays: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
})

const queryClient = new QueryClient()

export default function App({ Component, pageProps }: AppProps) {
  const muiTheme = createTheme(themeOptions)
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) return <></>

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={muiTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="zh">
          <Component {...pageProps} />
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}
