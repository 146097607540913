import { baseColor } from '../../tailwind.config'

import type { ThemeOptions } from '@mui/material'

export const themeOptions = {
  palette: {
    primary: {
      main: baseColor.primary,
    },
  },
} as ThemeOptions
